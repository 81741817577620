<template>
  <v-app>
    <v-app-bar
      app
      color="blue-grey-darken-2"
      dense
      dark
      v-show="isAppBarVisible()"
      clipped-left
      >
      <v-app-bar-nav-icon
          v-on:click="toggleNavBarVisibility()">
      </v-app-bar-nav-icon>
      <v-toolbar-title>jtWMS</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
          left
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>{{ getBellBadge() }}</v-icon>
          </v-btn>
        </template>

          <v-list two-line>
            <v-list-item-group>
            <template v-for="item in notifications">
              <v-list-item
                  :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.message }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
        </v-list-item-group>
          </v-list>
      </v-menu>
      <v-menu
        left
        bottom
        offset-y
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list
          dense
      >
        <v-list-item
            @click="displayDocumentation"
        >
          <v-list-item-title>Documentation...</v-list-item-title>
        </v-list-item>
        <v-list-item
            @click="toggleDarkLightMode"
        >
          <v-list-item-title id="toggleDarkLightModeItemText">{{ toggleDarkModeText }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="logout"
        >
          <v-list-item-title >Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <v-navigation-drawer
      app
      :permanent="isDrawerVisible"
      :width="calculateNavBarHeight()"
      clipped
      flat
      v-show="isDrawerVisible"
  >
    <Nav />
  </v-navigation-drawer>
  <v-main
  >
    <v-container
    >
      <router-view></router-view>
    </v-container>
  </v-main>
  <v-footer app dense color="#EEEEEE">
    <v-spacer></v-spacer>
    jtWMS - 2024
    <v-spacer></v-spacer>
    Status: OK
  </v-footer>
</v-app>
</template>

<script setup>

</script>

<script>
import Nav from './components/Nav.vue';
import * as users from './js/users';
import * as configs from './js/configurations';
import axios from "axios";

export default {
  name: 'App',
  components: {
    Nav
  },
  data: () => ({
    isDrawerVisible: false,
    isNavBarVisible: false,
    group: null,
    toggleDarkModeText: 'Turn on dark mode',
    notificationsExist: true,
    notifications: []
  }),
  methods: {
    isAppBarVisible() {
      if (users.isUserLoggedIn()) {
        return true;
      } else {
        return false;
      }
    },
    calculateNavBarHeight() {
      if (!users.isUserLoggedIn() || !this.isDrawerVisible) {
        return 0;
      }
    },
    async logout() {
      localStorage.removeItem("user");
      this.isDrawerVisible = false;
      this.isNavBarVisible = false;
      await this.$router.push('/login');
    },
    async toggleDarkLightMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark) {
        this.toggleDarkModeText = "Turn on light mode";
      } else {
        this.toggleDarkModeText = "Turn on dark mode";
      }
    },
    toggleNavBarVisibility: function() {
      const navPreference = users.getUserNavPreference();
      users.setUserNavPreference(!navPreference);
      this.isDrawerVisible = !navPreference;
    },
    async displayDocumentation() {
      let a= document.createElement("a");
      a.target= "_blank";
      a.href= "http://" + configs.getHostUrl() + ":" + configs.getPort() + "/api/docs/jasons-wms-home/";
      a.click();
    },
    viewNotifications: function() {

    },
    getBellBadge() {
      if (this.notificationsExist) {
        return 'mdi-bell-badge';
      }

      return 'mdi-bell';
    },
    getNotifications: async function() {
      this.notifications = {};
      const appsUrl = 'http://' + configs.getHostUrl() + ':' + configs.getPort() + '/api/alert-management/Alerts/';
      let result = await axios.get(appsUrl);
      this.notifications = result.data.alerts;
    }
  },
  mounted: async function() {
    this.isDrawerVisible = users.getUserNavPreference();

    const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
    this.$vuetify.theme.dark = users.getUserDarkModePreference();
    await this.getNotifications();
    Notification.requestPermission().then((result) => {
      console.log(result);
      this.notifications.forEach((element) => {
        console.log("Handling a notification: ", element);
      });
    });


  }
};
</script>
