/**
 * Returns a string based state based on the enum / int provided value.
 * @param state
 * @returns {string}
 */
export function getStateStringFormat(state) {
    if (state === 0) {
        return 'Created';
    } else if (state === 1) {
        return 'Pending Release';
    } else if (state === 2) {
        return 'Released';
    } else if (state === 3) {
        return 'Fulfilled';
    } else if (state === 4) {
        return 'Consolidated';
    } else if (state === 5) {
        return 'Packed';
    } else if (state === 6) {
        return 'Shipped';
    } else if (state == 7) {
        return 'On Hold';
    } else {
        return 'Shipped';
    }
}

/**
 * Gets and returns a list of orders from the backend service.
 */
export function getOrders() {
    configurations.getHostUrl();
}