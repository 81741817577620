<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Fetch {{ itemCount }} items from {{ packLocation }}
      </v-toolbar>
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import * as orderConsolidation from "@/js/OrderConsolidation";

export default {
  name: 'InductSkuForConsolidation',
  props: {
    msg: String
  },
  data() {
    return {
      packLocation: 'PACK01',
      packingTask: {},
      itemCount: 0,
      dismissCountDown: 0,
      dismissSeconds: 10,
      variant: "success",
      tempMessage: "Test",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert: function(errorMessage) {
      this.tempMessage = errorMessage;
      this.variant = "warning";
      this.isAlertVisible = true;
    },
    isConfirmDisabled: function() {
      return false;
    },
    confirm: async function() {
      location.href = '#/orderConsolidation/packing/packOrder/induct'
    },
  },
  mounted: async function () {
    let nextPackingTask = await orderConsolidation.getNextPackingTask();
    this.packLocation = nextPackingTask.items[0].sourceLocation;
    this.packingTask = nextPackingTask;
    this.itemCount = nextPackingTask.items.reduce((sum, {quantityRequested}) => sum + quantityRequested, 0);
    console.log(nextPackingTask);
  }
}
</script>