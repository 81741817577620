<template>
  <v-toolbar
      color="grey"
      dark
      dense
      flat
  >
    <v-toolbar-title class="text-md-body-1">
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        v-show="searchEnabled"
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn
        small
        @click="onCreate"
        color="grey-darken-3"
        class="ma-2 white--text"
        v-show="showButtons"
    >
      <v-icon
          left
          dark
      >
        mdi-pencil
      </v-icon>
      Create
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "OverviewToolbar",
  props: {
    title: '',
    onCreate: Function,
    search: '',
    searchEnabled: true,
    showButtons: true,
  },
};
</script>

<style scoped>

</style>