<template>
  <div>
    <v-alert
        v-model="alert"
        :show="dismissCountDown"
        dismissible
        :type="alertType"
        border="left"
        elevation="2"
        colored-border
        @dismissed="dismissCountDown===0"
        @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}
    </v-alert>
    <v-card>
      <OverviewToolbar
          :title="title"
          v-model="search"
      />
      <v-data-table
          :items="consolidationTasks"
          :headers="headers"
          :search="search"
          :loading="isLoading"
          no-data-text="No data"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1"
          @row-clicked="navToConsolidationTaskDetails"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
              :color="getStateColor(item.status)"
              dark
              small
          >
            {{ formatState(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.skus="{ item }">
          <v-chip
              color="blue"
              dark
              small
          >
            {{ getSkuCount(item.items) }}
          </v-chip>
        </template>
        <template v-slot:item.itemQuantity="{ item }">
          <v-chip
              color="blue"
              dark
              small
          >
            {{ getItemCount(item.items) }}
          </v-chip>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import WidgetGroup from '../Dashboard/Components/WidgetGroup.vue';
import * as configuration from '../../js/configurations';
import * as consolidation from '../../js/OrderConsolidation'
import Toolbar from "@/components/Base/OverviewToolbar.vue";
import OverviewToolbar from "@/components/Base/OverviewToolbar.vue";

export default {
  name: 'ConsolidationListView',
  props: {
    msg: String
  },
  components: {
    OverviewToolbar,
    Toolbar,
    WidgetGroup
  },
  data() {
    return {
      title: "Consolidation Tasks",
      consolidationTasks: [],
      headers: [
        { value: 'actions', text: 'Actions', sortable: false, filterable: false },
        { text: "Task", value: "taskId" },
        { text: "Status", value: "status" },
        { text: "SKUs", value: 'skus' },
        { text: 'Items', value: 'itemQuantity' }
      ],
      search: '',
      isLoading: true,
      alert: false,
      alertType: 'info',
      alertMessage: '',
      dismissCountDown: 10,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    formatState: function(status) {
      return consolidation.formatStatus(status);
    },
    formatDate: function(date) {
      return configuration.formatDate(date);
    },
    getStateColor: function(status) {
      if (status === 0) {
        return 'blue';
      } else if (status === 1) {
        return 'teal';
      }

      return 'green';
    },
    getItemCount: function(items) {
      return items.reduce((n, {quantityRequested}) => n + quantityRequested, 0)
    },
    getSkuCount: function(items) {
      return 1;
    },
    deleteItem: async function(item) {
      this.alert = true;
      this.alertMessage = 'Delete functionality is a future feature. Please check back soon!';
      this.dismissCountDown = 10;
    },
    editItem: function(item) {
      this.alert = true;
      this.alertMessage = 'Edit functionality is a future feature. Please check back soon!';
      this.dismissCountDown = 10;
    },
    getContainerCount: function(containerCount) {
      if (containerCount > 1 || containerCount === 0) {
        return containerCount + ' containers';
      } else {
        return containerCount + ' container';
      }
    },
    navToConsolidationTaskDetails: function() {
      this.alert = true;
      this.alertMessage = 'View details functionality is a future feature. Please check back soon!';
      this.dismissCountDown = 10;
    },
  },
  mounted: async function () {
    this.consolidationTasks = await consolidation.getConsolidationTasks();
    this.isLoading = false;
  }
}
</script>
