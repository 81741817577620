<template>
  <div>
    <v-alert
        v-model="alert"
        :show="dismissCountDown"
        dismissible
        :type="alertType"
        border="left"
        elevation="2"
        colored-border
        @dismissed="dismissCountDown===0"
        @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}
    </v-alert>
    <v-card>
      <OverviewToolbar
          :title="title"
          v-model="search"
      />
      <v-data-table
        :items="orders"
        :headers="headers"
        :search="search"
        :loading="isLoading"
        no-data-text="No data"
        loading-text="Loading... Please wait"
        dense
        class="elevation-1"
        @click:row="(_, item) => navToOrderDetails(item)"
      >
        <template
          v-slot:item.orderId="{ item }"
          onclick="navToOrderDetails(item)"
        >
            {{ item.orderId }}
        </template>
        <template v-slot:item.state="{ item }">
          <v-chip
              :color="getStateColor(item.state)"
              dark
              small
          >
            {{ formatState(item.state) }}
          </v-chip>
        </template>
        <template v-slot:item.latestShipTime="{ item }">
          <v-row>
            {{ formatDate(item.latestShipTime) }}
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import * as orderService from '../js/orders';
import OverviewToolbar from "@/components/Base/OverviewToolbar.vue";

export default {
  name: 'Orders',
  components: {OverviewToolbar},
  isLoading: true,
  props: {
    msg: String
  },
  data() {
    return {
      orders: [],
      title: "Orders",
      headers: [
        {value: 'orderId', text: 'Order'},
        {value: 'state', text: 'State'},
        {value: 'priority', text: 'Priority'},
        {value: 'orderItems.length', text: 'Items'},
        {value: 'latestShipTime', text: 'Ship Time'}
      ],
      breadcrumbs: [
        {text: "Home", href: "/"},
        {text: "Operations"},
        {text: "Orders"}
      ],
      currentPage: 1,
      totalRows: 1,
      rowsPerPage: 20,
      filter: null,
      search: '',
      isLoading: true,
      dismissCountDown: 10,
      alert: false,
      alertType: "warning",
      alertMessage: "",
    }
  },
  methods: {
    formatDate(value) {
      let date = new Date(value);
      return date.toLocaleString();
    },
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    formatState: function (state) {
      return orderService.getStateStringFormat(state);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    navToOrderDetails(rowElement, item) {
      console.log(rowElement);
      console.log(item)
      location.href = '#/orders/' + rowElement.item.orderId;
    },
    getStateColor: function (state) {
      if (state === 0) {
        return 'blue';
      } else if (state === 1) {
        return 'teal';
      }

      return 'green';
    },
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Order';
    this.$http.get(appsUrl)
        .then((result) => {
          this.orders = result.data;
          this.isBusy = false;
          this.totalRows = this.orders.length;
          this.isLoading = false;
        })
        .catch(function (error) {
          console.error('Encountered the following error:', error.toJSON());
        })
  }
}
</script>