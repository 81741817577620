<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Induct SKU
      </v-toolbar>
      <v-text-field
          v-model="skuId"
          placeholder="SKU or UPC"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import {OrderConsolidation} from "@/js/OrderConsolidation";

export default {
  name: 'InductSkuForConsolidation',
  props: {
    msg: String
  },
  data() {
    return {
      userId: 'test1',
      zoneId: 'zone1',
      skuId: '',
      currentState: '',
      delivery: '',
      dismissCountDown: 0,
      dismissSeconds: 10,
      variant: "success",
      tempMessage: "Test",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert: function(errorMessage) {
      this.tempMessage = errorMessage;
      this.variant = "warning";
      this.isAlertVisible = true;
    },
    isConfirmDisabled: function() {
      if (this.skuId == '') {
        return true;
      }

      return false;
    },
    confirm: async function() {
      if (this.skuId === '') {
        this.tempMessage = 'Please identify a location or zone to continue.';
        this.variant = "warning";
        this.dismissCountDown = 10;
        return;
      }


      const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/order-consolidation/ConsolidationTasks/Induct';
      console.log("URL: ", appsUrl);

      let inductRequest = {};
      inductRequest.barcode = this.skuId;
      inductRequest.userId = 1;
      inductRequest.zoneId = this.zoneId;

      try {
        console.log('Hello there');
        const response = await this.$http.post(appsUrl, inductRequest);
        console.log('Response: ', response);
        let inductionResponse = response.data;
        console.log('InductionResponse', inductionResponse);
        OrderConsolidation.currentItem = inductionResponse.items[0];
        OrderConsolidation.inductedTaskId = inductionResponse.taskId;
        location.href = '#/orderConsolidation/putItem';
      } catch (exception) {
        if (exception.response.data.reason) {
          this.showAlert(exception.response.data.reason)
        } else if (exception.request) {
          this.tempMessage = 'An error occurred';
          this.showAlert(this.tempMessage)
          console.error('An error occurred while inducting the delivery container: ', exception.request);
        } else {
          this.tempMessage = 'An error occurred';
          this.showAlert(this.tempMessage)
          console.error('An error occurred while inducting the delivery container: ', exception.message);
        }
      }
    },
  },
}
</script>