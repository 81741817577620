<template>
  <div>
    <v-alert
        v-model="isAlertVisible"
        :type="variant"
        dismissible>
      {{ tempMessage }}
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Put 1 at {{ OrderConsolidation.currentItem.destinationLocation }}
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row align="center">
            <v-col class="text-h2" cols="8">
              1 ea
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list>
                <v-list-item class="text-h5">
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker</v-icon>
                    <v-list-item>{{ OrderConsolidation.currentItem.destinationLocation }}</v-list-item>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item class="text-h5">
                  <v-list-item-icon>
                    <v-icon>mdi-image-text</v-icon>
                    <v-list-item>{{ OrderConsolidation.currentItem.description }}</v-list-item>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
                v-model="location"
                placeholder="Location"
                v-on:keyup.enter="confirm()"
                autofocus
            />
          </v-row>
          <v-row>
            <v-btn
                v-on:click="confirm()"
                :dark="!isConfirmDisabled()"
                :disabled="isConfirmDisabled()"
            >
              Confirm
            </v-btn>
          </v-row>
        </v-card-text>

      </v-card>

    </div>
  </div>
</template>
<script>

import {OrderConsolidation} from "@/js/OrderConsolidation";

export default {
  name: 'PutItem',
  computed: {
    OrderConsolidation() {
      return OrderConsolidation
    }
  },
  props: {
    msg: String
  },
  data() {
    return {
      userId: 'test1',
      zoneId: 'zone1',
      skuId: '',
      location: '',
      currentState: '',
      delivery: '',
      dismissCountDown: 0,
      dismissSeconds: 10,
      variant: "success",
      tempMessage: "Test",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    isConfirmDisabled: function() {
      return false;
    },
    confirm: async function() {
      if (this.location === '' || this.location === null) {
        return;
      }

      if (this.location !== OrderConsolidation.currentItem.destinationLocation) {
        this.isAlertVisible = true;
        this.tempMessage = 'Invalid location';
        this.variant = "warning";
        return;
      }

      const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/order-consolidation/ConsolidationTasks/ConfirmPut';

      let confirmPutRequest = {};
      confirmPutRequest.skuId = OrderConsolidation.currentItem.sku;
      confirmPutRequest.destinationLocation = OrderConsolidation.currentItem.destinationLocation;
      confirmPutRequest.userId = 1;
      confirmPutRequest.zoneId = 'PW01';
      confirmPutRequest.taskId = OrderConsolidation.inductedTaskId;
      confirmPutRequest.quantityPut = 1;

      try {
        const response = await this.$http.post(appsUrl, confirmPutRequest);
        location.href = '#/orderConsolidation/inductSku';
      } catch (exception) {
        if (exception.response) {
          this.tempMessage = exception.response.data.reason;
          this.dismissCountDown = 10;
          this.isAlertVisible = true;
        } else if (exception.request) {
          this.tempMessage = 'An error occurred';
          this.dismissCountDown = 10;
          this.isAlertVisible = true;
          console.error('An error occurred while inducting the delivery container: ', exception.request);
        } else {
          this.tempMessage = 'An error occurred';
          this.dismissCountDown = 10;
          this.isAlertVisible = true;
          console.error('An error occurred while inducting the delivery container: ', exception.message);
        }
      }
    },
  },
}
</script>