<template>
  <div>
    <v-alert
        v-model="alert"
        :show="dismissCountDown"
        dismissible
        :type="alertType"
        border="left"
        elevation="2"
        colored-border
        @dismissed="dismissCountDown===0"
        @dismiss-count-down="countDownChanged"
    >
      {{ alertMessage }}
    </v-alert>
    <v-card>
      <OverviewToolbar
          :title="title"
          :on-create="createDeliveryClicked"
          v-model="search"
      />
      <v-data-table
          :items="deliveries"
          :headers="headers"
          :search="search"
          :loading="isLoading"
          no-data-text="No data"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.state="{ item }">
          <v-chip
              :color="getStateColor(item.state)"
              dark
              small
          >
            {{ formatState(item.state) }}
          </v-chip>
        </template>
        <template v-slot:item.arrivalDate="{ item }">
          <v-row>
            {{ formatDate(item.arrivalDate) }}
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>

import WidgetGroup from '../Dashboard/Components/WidgetGroup.vue';
import * as delivery from '../../js/delivery.js';
import * as configuration from '../../js/configurations';
import Toolbar from "@/components/Base/OverviewToolbar.vue";
import OverviewToolbar from "@/components/Base/OverviewToolbar.vue";

export default {
  name: 'DeliveryOverview',
  props: {
    msg: String
  },
  components: {
    OverviewToolbar,
    Toolbar,
      WidgetGroup
  },
  data() {
      return {
        title: "Deliveries",
        deliveries: [],
        headers: [
          { value: 'actions', text: 'Actions', sortable: false, filterable: false },
          { text: "Delivery", value: "taskId" },
          { text: "Status", value: "state" },
          { text: "Container Count", value: "containers.length" },
          { text: "Arrival", value: "arrivalDate" }
        ],
        search: '',
        isLoading: true,
        alert: false,
        alertType: 'info',
        alertMessage: '',
        dismissCountDown: 10,
      }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
      formatState: function(state) {
        return delivery.formatDeliveryState(state);
      },
      formatDate: function(date) {
        return configuration.formatDate(date);
      },
      createDeliveryClicked: function() {
        location.href = '#/delivery/createEditDelivery';
      },
      getStateColor: function(state) {
        if (state === 0) {
          return 'blue';
        } else if (state === 1) {
          return 'teal';
        }

        return 'green';
      },
    deleteItem: async function(item) {
      this.alert = true;
      this.alertMessage = 'Delete functionality is a future feature. Please check back soon!';
      this.dismissCountDown = 10;
    },
    editItem: function(item) {
      this.alert = true;
      this.alertMessage = 'Edit functionality is a future feature. Please check back soon!';
      this.dismissCountDown = 10;
      location.href = '#/delivery/createEditDelivery/' + item.taskId;
    },
    getContainerCount: function(containerCount) {
        if (containerCount > 1 || containerCount === 0) {
          return containerCount + ' containers';
        } else {
          return containerCount + ' container';
        }
    }
  },
  mounted: async function () {
    this.deliveries = await delivery.getDeliveries();
    this.isLoading = false;
  }  
}
</script>
