<template>
  <v-app>
    <v-alert
      v-model="alertVisible"
      dismissible
      dark
      type="error"
    >
      {{ alertMessage }}
    </v-alert>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar
                color="blue-grey-darken-2"
                dark
              >
                <v-toolbar-title>jtWMS Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text

              >
                <v-form>
                  <v-text-field
                      prepend-icon="mdi-account"
                      name="login"
                      label="Username"
                      type="text"
                      v-model="username"
                      v-on:keyup.enter="onLogin"
                      autofocus
                  ></v-text-field>
                  <v-text-field
                      id="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Password"
                      type="password"
                      v-model="password"
                      v-on:keyup.enter="onLogin"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <a href="http://wms.jasontierney.com/api/docs/Get%20Started/privacy-policy/">Privacy Policy</a>
                <v-spacer></v-spacer>
                <v-btn color="#EEEEEE" @click="onLogin">Log in</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import * as users from "../js/users.js";

export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
      return {
          username: '',
          password: '',
        alertMessage: '',
        alertVisible: false
      }
  },
  methods: {
    isSubmitButtonDisabled() {
      return this.username === '' || this.username.length < 1;
    },
    async onSignup() {
      await this.$router.push('/accounts/createAccount');
    },
    async onLogin() {
      let isAzureAdLogin = false;
      if (isAzureAdLogin) {
        await azureLogin();
      } else {
        let response = await users.userLogin(this.username, this.password);
        if (response != null && response !== "") {
          this.alertMessage = response;
          this.alertVisible = true;
        } else {
          this.alertMessage = "";
          this.alertVisible = false;
          await this.$router.push('/warehouseOverview');
        }
      }
    },
  },
  mounted: function () {
    let userToken = localStorage.getItem("user");
    if (!userToken) {
      return;
    }

    this.$router.push('/warehouseOverview');
  }  
}
</script>