<template>
  <div>
    <CreateEditToolbar
        :title="title + orderId"
        :on-back="navAways"
    />
    <v-card>
      <v-card-text>
        <v-text-field
            v-model="orderId"
            @input="fetchOrder"
            placeholder="Order ID"
            label="Order"
            autofocus>
        </v-text-field>
      </v-card-text>
    </v-card>
    <br/>
    <v-card>
      <v-card-title>
        Summary
      </v-card-title>
      <v-row>
        <v-col>
          <v-card-text>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cart-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ order.orderId }}</v-list-item-title>
                <v-list-item-subtitle>Order</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ formatDate(order.latestShipTime) }}</v-list-item-title>
                <v-list-item-subtitle>Requested Ship Date</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-invoice-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ orderItems.length }}</v-list-item-title>
                <v-list-item-subtitle>Line Items</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-counter</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ orderItems.map(item => item.quantityRequested).reduce((prev, next) => prev + next) }}</v-list-item-title>
                <v-list-item-subtitle>Quantity Requested</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            <v-timeline
                align-top
                dense
            >
              <v-timeline-item
                  v-for="orderState in orderStates"
                  :key="orderState.state"
                  :color="orderState.color"
                  small
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ orderState.state }}</strong>
                  </div>
                  <div>{{ orderState.message }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <br/>
    <v-card>
      <v-card-title>Items</v-card-title>
      <v-card-text>
        <v-data-table striped hover class="table table-bordered"
          :items="orderItems"
          :headers="orderItemHeaderFields"
          head-variant="light"
          :busy="isBusy"
                      dense
        >
          <template
              v-slot:item.skuId="{ item }"
          >
            <v-row v-on:click="navToInventory(item.skuId)">
              {{ item.skuId }}
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import 'vue-step-progress/dist/main.css';
import * as orders from '../js/orders.js';
import CreateEditToolbar from "@/components/Base/CreateEditToolbar.vue";

export default {
  components: {
    CreateEditToolbar,
  },
  name: 'OrderDetails',
  props: {},
  data() {
    return {
      title: 'Order Details - ',
      orderId: '',
      order: {},
      orderItems: [],
      orderStates: [
        {state: 'Created', color: 'blue', message: 'Created from OrderGenerator'},
        {state: 'Released', color: 'blue', message: 'Released from OrderGenerator'},
        {state: 'Fulfilled', color: 'blue', message: 'Fulfilled from OrderGenerator'},
        {state: 'Packed', color: 'blue', message: 'Packed from OrderGenerator'},
        {state: 'Shipped', color: 'blue', message: 'Shipped from OrderGenerator'},
      ],
      orderHeaderFields: [
        {value: 'orderId', text: 'Order'},
        {
          value: 'state', text: 'Status',
          formatter: (value, key, item) => {
            return this.formatState(value);
          }
        },
        {value: 'priority', text: 'Priority'},
        {
          value: 'latestShipTime', text: 'Ship Time',
          formatter: (value, key, item) => {
            return this.formatDate(value);
          },
        }
      ],
      orderItemHeaderFields: [
        { value: 'skuId', text: 'SKU' },
        { value: 'quantityRequested', text: 'Requested' },
        { value: 'quantityFulfilled', text: 'Fulfilled' },
        { value: 'quantityConsolidated', text: 'Consolidated' },
        { value: 'quantityPacked', text:'Packed' },
        { value: 'zoneId', text: 'Zone'}
      ],
    }
  },
  methods: {
    formatState: function (state) {
      return orders.getStateStringFormat(state);
    },
    fetchOrder: function () {
      this.getOrderFromServer();
    },
    getOrderFromServer: function () {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Order/' + this.orderId
      this.$http.get(appsUrl)
          .then((result) => {
            this.order = [];
            if (result.data == null || result.data === "") {
              return;
            }

            if (result.data.orderId == null || result.data.orderId === "") {
              return;
            }

            console.log('Data: ', result.data);
            this.order = result.data;
            this.orderItems = this.order.orderItems;
            this.formatState(this.order.state);

            console.log('Order: ', this.order);
            console.log('OrderState: ', this.order.state);
          });
    },
    formatDate(value) {
      let date = new Date(value);
      return date.toLocaleString();
    },
    navToInventory(skuId) {
      location.href = '#/inventoryLookup/' + skuId;
    },
    navAways() {
      console.log('Naving away');
      location.href = '#/orders';
    },
  },
  mounted: function () {
    this.orderId = this.$route.params.orderId
    if (this.orderId == null || this.orderId === '') {
      return;
    }

    this.getOrderFromServer();
  }
}
</script>