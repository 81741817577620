import { reactive } from "vue";
import axios from "axios";
import * as configurations from './configurations.js';

export const OrderConsolidation = reactive({
    currentItem: {},
    inductedTaskId: 0
});

export function getConsolidationBaseUrl() {
    return 'http://' + configurations.getHostUrl() + ':' + configurations.getPort() + '/api/order-consolidation/';
}

export function getConsolidationTaskApiUrl() {
    return getConsolidationBaseUrl() + 'ConsolidationTasks';
}

export function getPackingTaskApiUrl() {
    return getConsolidationBaseUrl() + 'PackingTasks';
}

export async function getConsolidationTasks() {
    let getTasksUrl = getConsolidationTaskApiUrl();
    let result = await axios.get(getTasksUrl);
    return result.data.consolidationTasks;
}

export async function getPackingTasks() {
    let getPackingTasksUrl = getPackingTaskApiUrl();
    let result = await axios.get(getPackingTasksUrl);
    return result.data.packingTasks;
}

export async function getPackingTasksById(id) {
    let packingTasks = getPackingTasks();
    for (let packingTask in packingTasks) {
        if (packingTask.id === id) {
            return packingTask;
        }
    }
}

export async function getNextPackingTask() {
    let packingTasks = await getPackingTasks();
    if(packingTasks.length < 1) {
        return null;
    }

    for (let i = 0; i < packingTasks.length; i++) {
        if (packingTasks[i].status < 3) {
            return packingTasks[i];
        }
    }

    return null;
}

export function formatStatus(status) {
    /**
     * Created,
     *     Released,
     *     InProgress,
     *     Completed
     */
    if (status === 0) {
        return 'Created';
    } else if (status === 1) {
        return 'Released';
    } else if (status === 2) {
        return 'In Progress';
    } else if (status === 3) {
        return 'Completed';
    } else {
        return 'Unknown';
    }
}