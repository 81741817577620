<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Pack Item
      </v-toolbar>
      <v-text-field
          v-model="skuId"
          placeholder="Scan SKU / UPC"
          v-on:keyup.enter="confirm()"
          autofocus
      />
      <v-btn-toggle>
        <v-btn
            v-on:click="confirm()"
            :dark="!isConfirmDisabled()"
            :disabled="isConfirmDisabled()"
        >
          Confirm
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
import {getPackingTasksById, OrderConsolidation} from "@/js/OrderConsolidation";

export default {
  name: 'InductSkuForConsolidation',
  props: {
    msg: String
  },
  data() {
    return {
      userId: 'test1',
      packingTask: {},
      packingTaskId: 0,
      zoneId: 'zone1',
      skuId: '',
      currentState: '',
      delivery: '',
      dismissCountDown: 0,
      dismissSeconds: 10,
      variant: "success",
      tempMessage: "Test",
      isAlertVisible: false,
    }
  },
  methods: {
    countDownChanged: function(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert: function(errorMessage) {
      this.tempMessage = errorMessage;
      this.variant = "warning";
      this.isAlertVisible = true;
    },
    isConfirmDisabled: function() {
      return this.skuId === '';
    },
    confirm: async function() {
      if (this.skuId === '') {
        this.tempMessage = 'Please identify a location or zone to continue.';
        this.variant = "warning";
        this.dismissCountDown = 10;
        return;
      }

      const appsUrl = `http://${this.$host}:${this.$configurationServicePort}/api/order-consolidation/PackingTasks/PackItem/`;
      console.log("URL: ", appsUrl);

      let packItemRequest = {};
      packItemRequest.skuId = this.skuId;
      packItemRequest.userId = 1;
      packItemRequest.zoneId = this.zoneId;
      packItemRequest.packingTaskId = this.packingTaskId;
      try {
        const response = await this.$http.post(appsUrl, packItemRequest);
        let packItemResponse = response.data.packingTask;
        if (packItemResponse.status === 3) {
          this.tempMessage = "Packing Order Complete!";
          this.variant = "success";
          this.showAlert(this.tempMessage)
          location.href = '#/orderConsolidation/packing/packOrder';
        }

        this.skuId = '';
      } catch (exception) {
        if (exception.response.data.reason) {
          this.showAlert(exception.response.data.reason)
        } else if (exception.request) {
          this.tempMessage = 'An error occurred';
          this.showAlert(this.tempMessage)
          console.error(`An error occurred while inducting the delivery container:${exception.request}`);
        } else {
          this.tempMessage = 'An error occurred';
          this.showAlert(this.tempMessage)
          console.error('An error occurred while inducting the delivery container: ', exception.message);
        }
      }
    },
  },
  mounted: async function() {
    this.packingTaskId = this.$route.params.packingTaskId;
    this.packingTask = await getPackingTasksById(this.packingTaskId);
    console.log('Packing Task: ', this.packingTask);
    console.log('And its items: ', this.packingTask.items);
    if (this.packingTask === null) {
      this.tempMessage = 'Could not find the packing task';
      this.showAlert(this.tempMessage);
    }
  }
}
</script>