<template>
  <div>
    <v-card>
      <OverviewToolbar
          :title="title"
          :show-buttons="false"
          :search-enabled="false"
      />
      <v-container>
        <v-row>
          <v-col>
            <v-row></v-row>
            <v-row>
              <h3>Inbound</h3>
            </v-row>
            <v-row>
              <h4>Inventory</h4>
            </v-row>
            <v-row>
              <v-col v-for="(inventoryStat, i) in inventoryStats">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ inventoryStat.title }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ inventoryStat.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <h4>Receiving</h4>
            </v-row>
            <v-row>
              <v-col v-for="(deliveryStat, i) in deliveryStats">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ deliveryStat.title }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ deliveryStat.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <h4>Putaway</h4>
            </v-row>
            <v-row>
              <v-col v-for="(putawayStat, i) in putawayStats">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ putawayStat.title }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ putawayStat.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <h3>Order Fulfillment</h3>
            </v-row>
            <v-row>
              <h4>Order Statuses</h4>
            </v-row>
            <v-row>
              <v-col v-for="(orderStat, i) in orderStats">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ orderStat.title }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ orderStat.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <h4>Order Cycle Times</h4>
            </v-row>
            <v-row>
              <v-col v-for="(orderCycleTime, i) in orderCycleTimes">
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ orderCycleTime.title }}
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ orderCycleTime.content }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>

import OrderWidget from '../Components/OrderWidget.vue'
import WidgetGroup from '../Components/WidgetGroup.vue'
import axios from "axios";
import OperatorView from "@/components/Dashboard/WarehouseOverview/OperatorView";
import OrderThroughputChart from "@/components/Dashboard/Components/OrderThroughputChart.vue";
import OverviewToolbar from "@/components/Base/OverviewToolbar.vue";

export default {
  name: 'WarehouseOverview',
  props: {
    msg: String
  },
  components: {
    OverviewToolbar,
      OrderWidget,
      WidgetGroup,
      OperatorView,
      OrderThroughputChart
  },
  data() {
      return {
        unreleasedCount: 0,
        releasedCount: 0,
        activeCount: 0,
        completedCount: 0,
        openDeliveries: 0,
        value: 25,
        max: 35,
        orderCycleTime: 'Cycle Time',
        deliveryStats: [],
        putawayStats: [],
        orderStats: [],
        inventoryStats: [],
        counterInterval: 0,
        isContentLoaded: false,
        orderCycleTimes: [],
        title: 'Warehouse Overview',
      }
  },
  methods: {
    refresh: async function () {
      this.openDeliveries = 10;
      this.deliveryStats = [];
      this.putawayStats = [];
      this.orderStats = [];
      this.inventoryStats = [];

      this.refreshInventoryStats();
      this.refreshDeliveryStats();
      this.refreshPutawayStats();
      this.refreshOrderStats();
      await this.refreshOrderCycleTimes();
      this.isContentLoaded = true;
    },
    refreshInventoryStats: function() {
      const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory/stats';
      let inventoryStat = {};
      inventoryStat.title = 'Total Inventory';
      inventoryStat.content = '1000';
      this.inventoryStats.push(inventoryStat);

      let avgInventoryPerSkuStat = {};
      avgInventoryPerSkuStat.title = 'Avg. Inventory / SKU';
      avgInventoryPerSkuStat.content = 1500;
      this.inventoryStats.push(avgInventoryPerSkuStat);

      let totalSkus = {};
      totalSkus.title = 'Total SKUs';
      totalSkus.content = 100;
      this.inventoryStats.push(totalSkus);
    },
    refreshDeliveryStats: function() {
      const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingTask/stats'
        this.$http.get(appsUrl)
          .then((result) => {                
              this.deliveryStats = result.data;
          });
    },
    refreshPutawayStats: function() {
      const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway/stats'
        this.$http.get(appsUrl)
          .then((result) => {                
              this.putawayStats = result.data;
          });
    },
    refreshOrderStats: function() {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Order/GetOrderStatusCount';
      let tempOrderStats = [];
      this.$http.get(appsUrl)
          .then(async (result) => {
            tempOrderStats = result.data;
            let createdOrders = {
              title: "Created",
            };

            if (tempOrderStats['Created'] != null) {
              createdOrders.content = tempOrderStats['Created'];
            } else {
              createdOrders.content = 0;
            }

            this.orderStats.push(createdOrders);

            let inProgressOrders = {
              title: "Released / Active"
            };

            if (tempOrderStats['Released'] != null) {
              inProgressOrders.content = tempOrderStats['Released'];
            } else {
              inProgressOrders.content = 0;
            }

            this.orderStats.push(inProgressOrders);

            let completedOrders = new Object();
            completedOrders.title = "Fulfilled";
            if (tempOrderStats['PickingComplete'] != null) {
              completedOrders.content = tempOrderStats['PickingComplete'];
            } else {
              completedOrders.content = 0;
            }

            this.orderStats.push(completedOrders);

            let onHoldOrders = {
              title: "On Hold"
            };

            if (tempOrderStats['OnHold'] != null) {
              onHoldOrders.content = tempOrderStats['OnHold'];
            } else {
              onHoldOrders.content = 0;
            }

            this.orderStats.push(onHoldOrders);

            let orderThroughputState = await this.refreshOrderThroughput();
            this.orderStats.push(orderThroughputState);
          });
    },
    async refreshOrderCycleTimes() {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Reporting/AverageOrderCycleTimes';
      const auth = {
        headers: {
          Authorization:'JWT ' + localStorage.getItem('token')
        }
      }
      let response = await axios.get(appsUrl, auth);

      let releasedOrderCycleTime = new Object();
      releasedOrderCycleTime.title = "Time to Release";
      if(response.data['releasedCycleTime']) {
        releasedOrderCycleTime.content = Math.round(response.data['releasedCycleTime']);
      } else {
        releasedOrderCycleTime.content = 0;
      }

      this.orderCycleTimes.push(releasedOrderCycleTime);

      let pickCompleteCycleTime = new Object();
      pickCompleteCycleTime.title = 'Time to Pick';
      if (response.data['pickCompleteCycleTime']) {
        pickCompleteCycleTime.content = Math.round(response.data['pickCompleteCycleTime']);
      } else {
        pickCompleteCycleTime.content = 0;
      }

      this.orderCycleTimes.push(pickCompleteCycleTime);

      let totalOrderCycleTime = new Object();
      totalOrderCycleTime.title = 'Total Time to Complete';
      if (response.data['totalOrderCycleTime']) {
        totalOrderCycleTime.content = Math.round(response.data['totalOrderCycleTime']);
      } else {
        totalOrderCycleTime.content = 0;
      }

      this.orderCycleTimes.push(totalOrderCycleTime);
    },
    async refreshOrderThroughput() {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Reporting/AverageOrderThroughput';
      const auth = {
        headers: {Authorization:'JWT ' + localStorage.getItem('token')}
      }
      let response = await axios.get(appsUrl, auth);
      let orderThroughputStat = new Object();
      orderThroughputStat.title = 'Order Throughput';
      orderThroughputStat.content = response.data;
      return orderThroughputStat;
    },
    convertSecondsToTimeString: function(cycleTimeSeconds) {
        let timeString = '';
        let hours = 0;
        let minutes = 0;
        if (cycleTimeSeconds < 60) {
            this.orderCycleTime = "00:00:" + cycleTimeSeconds.toString();
        } else if (cycleTimeSeconds < 3600) {
            this.orderCycleTime = "00:";
            this.orderCycleTime += ((cycleTimeSeconds/60)).toString();
        } else if (cycleTimeSeconds < 36000) {
            hours = Math.floor(cycleTimeSeconds / 3600).toString();
            this.orderCycleTime = "0" + hours + ":";
        }
    }
  },
  mounted: function () {
    this.refresh();
  }
}
</script>